import { Box, Button, Divider, Stack, Typography, styled } from '@mui/material';
import BorderBox from 'components/BorderBox';
import blackListProps from 'styles/helpers/blackListProps';
import { PageTitle } from '../../components/PageContainer';

export const ObjectiveFormWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  width: '100%',
  overflowY: 'scroll',
  height: 'calc(100vh - 216px)',
  paddingBottom: theme.spacing(2),
}));

export const ObjectiveTotalWeightText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.A500,
}));

export const TotalWeightStack = styled(Stack)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const TotalWeightText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.A500,
  marginRight: theme.spacing(0.25),
}));

export const DetailsBox = styled(BorderBox)(({ theme }) => ({
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  width: '100%',

  '.MuiFormControlLabel-labelPlacementEnd .MuiFormControlLabel-label': {
    marginLeft: theme.spacing(0.5),
  },
  '.MuiTypography-subheadingSmall': {
    color: theme.palette.grey.A500,
  },
  '.MuiTypography-heading5': {
    color: theme.palette.grey.A500,
  },
}));

export const DashedDivider = styled(Divider)(() => ({
  '&::before, &::after': {
    borderTop: 'thin dashed rgba(0, 0, 0, 0.12)',
  },
}));

export const InputPostfix = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.A500,
}));

export const TotalGoalsWeightCounterMessage = styled(Box, {
  shouldForwardProp: blackListProps(['hasError']),
})<{ hasError: boolean }>(({ theme, hasError }) => ({
  ...theme.typography.subheadingSmall,
  marginRight: theme.spacing(2),
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey.A500,
  '.weight': {
    marginLeft: theme.spacing(0.75),
    color: theme.palette.common.black,
    '.delimiter': {
      color: theme.palette.common.black,
    },
    '.error': {
      color: hasError ? theme.palette.error.main : theme.palette.grey.A500,
    },
    '.correct': {
      color: theme.palette.common.black,
    },
    '.zero': {
      color: hasError ? theme.palette.error.main : theme.palette.grey.A500,
    },
  },
  '.MuiSvgIcon-root': {
    fontSize: 22,
    marginRight: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));

export const StatusColorBox = styled(Box)<{ color?: string }>(
  ({ color, theme }) => ({
    background: color,
    border:
      color === 'transparent' ? `1px solid ${theme.palette.grey.A400}` : 'none',
    width: '12px',
    height: '12px',
    borderRadius: '2px',
    marginRight: '8px',
  }),
);

export const ProgressBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ProgressBoxError = styled(Typography)(({ theme }) => ({
  color: theme.palette.red['500'],
}));

export const ProgressBox = styled(Box, {
  shouldForwardProp: blackListProps(['isAttainment']),
})<{ isAttainment?: boolean }>(({ isAttainment, theme }) => ({
  width: '134px',
  padding: '8px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  backgroundColor: isAttainment
    ? theme.palette.grey.A100
    : theme.palette.grey['100'],
  maxHeight: '66px',
  height: '100%',

  '.grayText': {
    color: theme.palette.grey['700'],
  },
}));

export const AgreedAttainmentUserField = styled(Box)(() => ({
  width: '70px',

  '.MuiInputBase-input.inputSize-large': {
    padding: '2px 8px',
    fontSize: '14px',
  },
}));

export const ArrowButton = styled(Button, {
  shouldForwardProp: blackListProps(['withMargin']),
})<{ withMargin?: boolean }>(({ theme, withMargin }) => ({
  '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
    marginLeft: withMargin ? theme.spacing(1) : theme.spacing(0.5),
    padding: '12px',
  },
  '.MuiButton-startIcon': {
    marginLeft: 0,
    marginRight: 0,

    '& > svg': {
      fontSize: '16px',
    },
  },
}));

export const ObjectiveCounter = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.A500,
}));

export const ParentBinding = styled(Stack)(({ theme }) => ({
  color: theme.palette.grey.A500,
}));

export const LastUpdateInfoWrapper = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

export const ObjectiveName = styled(PageTitle)(() => ({
  lineHeight: 'normal',
}));

export const ActionsList = styled(Stack)(() => ({
  minWidth: 350,
}));
